<template>
  <router-link :to="'item/' + category + '/' + slug"
               class="portfolio-item-preview"
               v-bind:style="{ backgroundImage:'url(\'http://sandervanderburgt.com:1337' + imageURL + '\')'  }">
    <h2>{{ title }}</h2>
    <div class="tags">
      <div class="tag" v-for="tag in tags">
        #{{ tag.Name }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ItemPreview",
  props: {
    title: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    imageURL: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/components/itempreview";
</style>