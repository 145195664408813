import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        jwt: Cookies.get("jwt")
    },
    mutations: {
        /**
         *
         * @param state this.state
         * @param jwt JWT token
         */
        jwt(state, jwt) {
            state.jwt = jwt
        },
        /**
         *
         * @param state this.state
         * @param jwt JWT token
         */
        login(state, jwt) {
            Cookies.set("jwt", jwt)
            state.jwt = jwt
        },
        /**
         *
         * @param state this.state
         */
        logout(state) {
            Cookies.remove("jwt")
            state.jwt = null
        }
    },
    actions: {},
    modules: {}
})
