<template>
  <div class="input-with-icon-container">
    <label>{{label.toUpperCase()}}</label>
    <div class="input-with-icon">
      <div class="icon">
        <i :class="`fad fa-` + icon"></i>
      </div>
      <input class="input" :type="type" :placeholder="placeholder" @input="$emit('input', $event.target.value)" >
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputWIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      input: null
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/components/inputWithIcon";
</style>