import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


// Vue apollo setup
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from '@apollo/client/link/context';

import VueApollo from 'vue-apollo'


// HTTP connection to the API
const httpLink = createHttpLink({
  // uri: 'http://localhost:4000',
  uri: 'http://sandervanderburgt.com:1337/graphql',
})


// Cache implementation
const cache = new InMemoryCache()

// bearer token authorization
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.state.jwt
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider: apolloProvider,
  render: h => h(App)
}).$mount('#app')
