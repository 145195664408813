<template>
  <div id="login-container">
    <div id="login-modal-container">
      <div id="login-header">
        <h2>Inloggen</h2>
        <p>Om dit portfolio te bekijken moet u eerst inloggen!</p>
      </div>
      <div id="login-form">
        <input-w-icon icon="user-shield" label="" placeholder="Gebruikersnaam" type="text" v-model="identifier" />
        <input-w-icon icon="key" label="" placeholder="Wachtwoord" type="password" v-model="password" id="last-input"/>
        <div id="error" v-if="error">
          {{ error }}
        </div>
        <button type="button" id="login-button" @click="loginUser()">Inloggen</button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import InputWIcon from "../components/InputWIcon";

export default {
  name: 'Login',
  components: {
    InputWIcon
  },
  data() {
    return {
      categoriesWithItems: null,
      identifier: ``,
      password: ``,
      error: null
    }
  },
  created() {
    var self = this
    // Deletes selected chart from the dashboard when delete key is pressed
    window.addEventListener('keyup', function(e) {
      if(e.key === "Enter") {
        self.loginUser()
      }
    });
  },
  methods: {
    loginUser() {

      this.error = null

      this.$apollo.mutate({
        mutation: gql`mutation login($identifier: String! $password: String!) {
        login(input:{identifier: $identifier password: $password}){
          jwt
        }
      }`,
        variables: {
          identifier: this.identifier,
          password: this.password,
        },

        update: (store, { data: { addTag } }) => {
          console.log("Updated")
        },
      }).then((result) => {

        const login = result.data.login

        if(login.jwt){
          this.$store.commit("login", login.jwt)
          this.$router.push({name: "Home"})
        }

      }).catch(({ graphQLErrors, networkError }) => {
        if(graphQLErrors){
          this.error = "Gebruikersnaam of wachtwoord incorrect!"
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/views/login";
</style>
