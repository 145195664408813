<template>
  <div class="phase">
    <div class="phase-divider-wrapper">
      <div class="phase-divider">
        <h1>{{ name }}</h1>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Phase",
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/components/phase";
</style>