<template>
  <div id="home-container">

    <div id="intro" v-if="intro" v-html="intro"/>

    <div id="portfolio-items">
      <phase v-for="phase in categoriesWithItems" :name="phase.Name">
        <item-preview v-for="item in sortItemsByPosition(phase.items)"
                      :title="item.Title"
                      :image-u-r-l="item.Image.url"
                      :slug="item.Slug"
                      :tags="item.tags"
                      :category="phase.Name.toLowerCase()"
        />
      </phase>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Phase from "../components/Phase";
import ItemPreview from "../components/ItemPreview";

import { orderBy } from "lodash";

export default {
  name: 'Home',
  components: {
    ItemPreview,
    Phase,
  },
  data() {
    return {
      categoriesWithItems: null,
      intro: ""
    }
  },
  methods: {
    sortItemsByPosition(items) {
      return _.sortBy(items, ['Position'])
    }
  },
  apollo: {
    getCategories: {
      query: gql`query categories {
        categories{
          Name
          items {
            Title
            Position
            Slug
            tags{
              Name
            }
            Image{
              name
              url
            }
          }
        }
      }`,
      update(data) {

      },
      result(result) {
        this.categoriesWithItems = result.data.categories
      },
      errorPolicy: "ignore",
      // pollInterval: 2000
      // skip: true
    },
    getIntro: {
      query: gql`query intro {
        intro{
          Title
          Content
        }
      }`,
      update(data) {
      },
      result(result) {
        if(result.data.intro){
          this.intro = result.data.intro.Content
        }
      },
      errorPolicy: "ignore",
      // pollInterval: 2000
      // skip: true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/views/home";

#intro::v-deep {

  a {
    color: $main-color;
  }
}
</style>
