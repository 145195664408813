<template>
  <div id="item-container">
    <div id="item" v-if="item">
      <div id="back-button">
        <router-link to="/">
          <i class="fad fa-long-arrow-left arrow-back" style="font-size: 1.6em"></i>
          <h4> Terug naar het overzicht</h4>
        </router-link>
      </div>

      <div id="item-header">
        <h1>{{ item.Title }}</h1>
        <div id="tags">
          <div class="tag" v-for="tag in item.tags">
            #{{ tag.Name }}
          </div>
        </div>
      </div>

      <div id="item-content" v-html="item.Content">
        <p>Content niet beschikbaar</p>
      </div>

      <div id="downloads" v-if="item.Files.length > 0">
        <h3>Bijlagen</h3>

        <div id="files">
          <div class="file" v-for="file in item.Files">
            <a :href="`http://sandervanderburgt.com:1337` + file.url" target="_blank">
              <i :class="`fad fa-file-image`" style="margin-right: 5px;"
                 v-if="file.ext === '.jpg' || file.ext === '.png'"></i>
              <i :class="`fad fa-file-pdf`" style="margin-right: 5px;" v-else-if="file.ext === '.pdf'"></i>
              <i :class="`fad fa-file`" style="margin-right: 5px;" v-else></i>
              {{ file.name }}
            </a>
          </div>
        </div>
      </div>

      <div id="next-previous-items">
        <div id="previous-item" v-if="previousItem">
          <i class="fad fa-long-arrow-left" style="font-size: 1.6em"></i>
          <router-link :to="previousItem.Slug">Vorige item</router-link>
        </div>
        <br />
        <div id="next-item" v-if="nextItem">
          <router-link :to="nextItem.Slug">Volgende item</router-link>
          <i class="fad fa-long-arrow-right" style="font-size: 1.6em"></i>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: 'Item',
  components: {},
  data() {
    return {
      itemSlug: null,
      item: null,
      allItems: null,
      previousItem: null,
      nextItem: null
    }
  },
  methods: {
    sortItemsByPosition(items) {
      return _.sortBy(items, ['Position'])
    },
    hello() {
      console.log("Helloooo!")
    },
    getPreviousItem(itemIndex, categoryIndex){
      var foundPreviousItem = null

      // if it's the first item in the category
      if(itemIndex === 0){
        var searchCategoryIndex = categoryIndex

        // loop to find last item in other categories before it
        while(searchCategoryIndex > 0){
          // get the category before the current one immidietly
          searchCategoryIndex--

          // If the array isn't empty, get the last item and return it
          if(this.allItems[searchCategoryIndex].items.length !== 0){
            var maxItemIndex = this.allItems[searchCategoryIndex].items.length -1
            foundPreviousItem = this.allItems[searchCategoryIndex].items[maxItemIndex]
            return foundPreviousItem
          }
        }
      }

      // default just return the previous one in line in the same category
      return this.allItems[categoryIndex].items[(itemIndex - 1)]
    },
    getNextItem(itemIndex, categoryIndex){
      var foundNextItem = null
      // if it's the last item in the category
      if(itemIndex === this.allItems[categoryIndex].items.length -1){

        var searchCategoryIndex = categoryIndex

        // loop to find last item in other categories after it
        while(searchCategoryIndex < this.allItems.length - 1){
          // get the category after the current one immediately
          searchCategoryIndex++

          // If the array isn't empty, get the last item and return it
          if(this.allItems[searchCategoryIndex].items.length !== 0){
            foundNextItem = this.allItems[searchCategoryIndex].items[0]
            return foundNextItem
          }
        }
      }

      // default just return the next one in line in the same category
      return this.allItems[categoryIndex].items[(itemIndex + 1)]
    }
  },
  created() {
    this.itemSlug = this.$route.params.slug
    window.scrollTo(0, 0);
  },
  apollo: {
    getItem: {
      query: gql`query ItemBySlug($Slug: String!) {
        ItemBySlug(Slug: $Slug){
          id
          Title
          Content
          Image {
            name
            url
          }

          Files {
            name
            url
            ext
          }

          categories{
            Name
          }
          tags{
            Name
          }

        }
      }`,
      variables() {
        return {
          Slug: this.itemSlug,
        }
      },
      update(data) {
      },
      result(result) {

        this.$apollo.queries.getItem.stop()

        if(!result.data.ItemBySlug){
          this.$router.push({name: "Home"})
        }

        var content = result.data.ItemBySlug.Content

        // Fix upload URL's, adds correct prefix
        var indexes = []
        var i = -1
        while((i = content.indexOf('<img', i + 1)) !== -1) {
          indexes.push(i);
          var imgTag = content.substring(i, content.indexOf('>', i) + 1);

          var uploadURL = "http://sandervanderburgt.com:1337"
          if(!imgTag.includes(uploadURL)) {
            var insertIndex = imgTag.indexOf('/')
            var newImgTag = [imgTag.slice(0, insertIndex), uploadURL, imgTag.slice(insertIndex)].join('');
            content = content.replace(imgTag, newImgTag)
          }
        }

        result.data.ItemBySlug.Content = content
        this.item = result.data.ItemBySlug

        this.$apollo.queries.getCategories.start()

      },
      errorPolicy: "ignore",
    },
    getCategories: {
      query: gql`query categories {
        categories{
          Name
          items {
            Title
            Position
            Slug
            tags{
              Name
            }
            Image{
              name
              url
            }
          }
        }
      }`,
      update(data) {
      },
      result(result) {
        console.log(result)
        this.$apollo.queries.getCategories.stop()

        // sort by position
        for(var i = 0; i < result.data.categories.length; i++){
          result.data.categories[i].items = this.sortItemsByPosition(result.data.categories[i].items)
        }

        this.allItems = result.data.categories

        var categoryIndex = _.findIndex(this.allItems, {"Name": this.item.categories[0].Name})
        var itemIndex = _.findIndex(this.allItems[categoryIndex].items, {"Slug": this.itemSlug})

        this.previousItem = this.getPreviousItem(itemIndex, categoryIndex)
        this.nextItem = this.getNextItem(itemIndex, categoryIndex)

      },
      errorPolicy: "ignore",
      // pollInterval: 2000
      skip: true
    },
  },
  updated() {
    this.$nextTick(function () {
      var galleries = document.getElementsByClassName("gallery")
      if(galleries.length > 0){
        for(var i = 0; i < galleries.length; i++) {
          var gallery = galleries[i]


        }
      }
    })
  },
  watch: {
    $route(to, from) {
      this.itemSlug = this.$route.params.slug
      this.$apollo.queries.getItem.start()

      window.scrollTo(0, 0);
    },
  },
}
</script>



<style lang="scss" scoped>
@import "../assets/scss/views/item";

//#item-content::v-deep img {
//  width: inherit;
//  max-width: 100%;
//}

//#item-content::v-deep iframe {
//  max-width: 100%;
//}

#item-content::v-deep {

  a {
    color: $main-color;
  }

  figcaption {
    color: $main-color;
    font-size: 0.6em;
  }

  iframe {
    max-width: 100%;
  }

  img {
    width: inherit;
    max-width: 100%;
  }

  .image-block {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;

    .image {
      margin: 2px;
      width: 49%;

      margin-bottom: 20px;

      img {
        width: 100%;
        border: 2px solid transparent;
      }

      img:hover {
        border: 2px solid $main-color;
        cursor: pointer;
      }
    }
  }

}


//
//
//#item-content::v-deep .gallery {
//  display: flex;
//  flex-wrap: wrap;
//
//  img {
//    width: 50%;
//    border: 3px solid transparent;
//  }
//
//  img:hover {
//    border: 3px solid $main-color;
//    cursor: pointer;
//  }
//}
</style>


