import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Cookies from 'js-cookie'

import Home from '../views/Home.vue'
import Item from '../views/Item.vue'
import Login from '../views/Login.vue'
import { logMissingFieldErrors } from "@apollo/client/core/ObservableQuery";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/item/:category/:slug',
    name: 'Item',
    component: Item
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if(to.name !== "Login" && !store.state.jwt){
    next({ name: 'Login' })
  }

  if(to.name === "Login" && store.state.jwt){
    next({ name: 'Home' })
  }

  next()
})


export default router
