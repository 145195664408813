<template>
  <div id="app">
    <div id="content-container">
      <div id="content">
        <div id="header">
          <div id="signature">
            <h2 style="letter-spacing: 0" @click="redirect" id="sig">SVDB</h2>
            <h5 v-if="this.$store.state.jwt" id="logout" @click="logout">Uitloggen</h5>
          </div>

          <div id="sub-header" v-if="this.$route.name === `Home`">
            <h1>Portfolio</h1>
            <h2>Semester <span class="accent-color"> 8 </span></h2>
            <h3>Afstudeerstage <span class="accent-color">2021 - 2022</span></h3>
          </div>
        </div>

        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    /*
    * Logs out the user and redirects them to the login page
    * */
    logout() {
      this.$store.commit("logout")
      this.$router.push({name: "Login"})
    },
    /*
    *
    * */
    redirect() {
      if(this.$route.name !== "Home"){
        this.$router.push({name: `Home`})
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/global.scss";
</style>
